import { FC, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import video from "../../assets/images/amb.mp4";
import newImage from "../../assets/images/new.svg";
import searchImage from "../../assets/images/search.svg";
import menuImage from "../../assets/images/menu.svg";
import minImage from "../../assets/images/min.svg";
import maxImage from "../../assets/images/max.svg";
import closeImage from "../../assets/images/close.svg";
import coverImage from "../../assets/images/glow.webp";
import ambrosiaImage from "../../assets/images/ambrosia_logo.svg";

class Vector {
  constructor(public x: number, public y: number, public z: number) {}

  rotate(dir: string, ang: number) {
    const X = this.x;
    const Y = this.y;
    const Z = this.z;

    const SIN = Math.sin(ang);
    const COS = Math.cos(ang);

    if (dir === "x") {
      this.y = Y * COS - Z * SIN;
      this.z = Y * SIN + Z * COS;
    } else if (dir === "y") {
      this.x = X * COS - Z * SIN;
      this.z = X * SIN + Z * COS;
    }
  }

  project(wh: number, camera: Vector) {
    const ZP = this.z + camera.z;
    const DIV = ZP / wh;
    const XP = (this.x + camera.x) / DIV;
    const YP = (this.y + camera.y) / DIV;
    const CENTER = getCenter(wh);
    return [XP + CENTER[0], YP + CENTER[1], ZP] as const;
  }
}

class Char {
  constructor(public letter: string, public pos: Vector) {}

  rotate(dir: string, ang: number) {
    this.pos.rotate(dir, ang);
  }

  render(CTX: CanvasRenderingContext2D, wh: number) {
    const PIXEL = this.pos.project(wh, camera);
    const XP = PIXEL[0];
    const YP = PIXEL[1];
    const MAX_SIZE = 50;
    const SIZE = ((1 / PIXEL[2]) * MAX_SIZE) | 0;
    const BRIGHTNESS = SIZE / MAX_SIZE;
    const COL = `rgba(255, 255, ${
      (100 * BRIGHTNESS) | (0 + 150)
    }, ${BRIGHTNESS})`;

    CTX.beginPath();
    CTX.fillStyle = COL;
    CTX.font = SIZE + "px monospace";
    CTX.fillText(this.letter, XP, YP);
    CTX.fill();
    CTX.closePath();
  }
}

function getCenter(wh: number) {
  return [ww / 2, wh / 2];
}

function signedRandom() {
  return Math.random() - Math.random();
}

let ww: number, wh: number, camera: Vector;

const MAX_CHARS = 250;
const SEPARATION = 1;
const CHARS: Char[] = [];

let time = 0;

function update(CTX: CanvasRenderingContext2D) {
  CTX.clearRect(0, 0, ww, wh);
  for (let i = 0; i < CHARS.length; i++) {
    const DX = 0.005 * Math.sin(time * 0.001);
    const DY = 0.005 * Math.cos(time * 0.001);
    CHARS[i].rotate("x", DX);
    CHARS[i].rotate("y", DY);
  }
  ++time;
}

function render(CTX: CanvasRenderingContext2D) {
  for (let i = 0; i < CHARS.length; i++) {
    CHARS[i].render(CTX, wh);
  }
}

function loop(CTX: CanvasRenderingContext2D) {
  window.requestAnimationFrame(() => {
    loop(CTX);
  });
  update(CTX);
  render(CTX);
}

function createChars() {
  for (let i = 0; i < MAX_CHARS; i++) {
    const CHARACTER = String.fromCharCode((Math.random() * 93 + 34) | 0);
    const X = signedRandom() * SEPARATION;
    const Y = signedRandom() * SEPARATION;
    const Z = signedRandom() * SEPARATION;
    const POS = new Vector(X, Y, Z);
    const CHAR = new Char(CHARACTER, POS);
    CHARS.push(CHAR);
  }
}

function setDim(canvas: HTMLCanvasElement) {
  ww = window.innerWidth;
  wh = window.innerHeight;
  canvas.width = (ww * window.devicePixelRatio) | 0;
  canvas.height = (wh * window.devicePixelRatio) | 0;
  const CTX = canvas.getContext("2d");
  if (CTX) {
    CTX.scale(window.devicePixelRatio, window.devicePixelRatio);
  }
}

function initCamera() {
  camera = new Vector(0, 0, SEPARATION + 1);
}

const Home: FC = () => {
  const codeText = [
    "ambVM is neither a blockchain nor a Layer 2 solution. Rather, it is a new way of thinking about and using inscriptions. We progress toward bringing general purpose computation to inscriptions to deliver Turing-complete and the highest flexible throughput to Bitcoin. All ambVM transactions are Bitcoin transactions, and all ambVM data are stored on Bitcoin.",
  ];
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const codeRef = useRef<HTMLHeadingElement>(null);

  function typeWriter(text: string, i: number, fnCallback: () => void) {
    if (codeRef.current) {
      if (i < text.length) {
        codeRef.current.innerHTML =
          text.substring(0, i + 1) + '<span aria-hidden="true"></span>';

        setTimeout(() => {
          typeWriter(text, i + 1, fnCallback);
        }, 50);
      } else if (typeof fnCallback === "function") {
        setTimeout(fnCallback, 500);
      }
    }
  }

  function startTextAnimation(i: number) {
    if (i >= codeText.length) {
      setTimeout(() => {
        startTextAnimation(0);
      }, 30000);
    } else if (i < codeText[i].length) {
      typeWriter(codeText[i], 0, () => {
        startTextAnimation(i + 1);
      });
    }
  }

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const CTX = canvas.getContext("2d");

      if (CTX) {
        setDim(canvas);
        initCamera();
        createChars();
        loop(CTX);
      }
    }
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.play();

      videoElement.addEventListener("pause", () => {
        videoElement.play();
      });

      videoElement.addEventListener("ended", () => {
        videoElement.play();
      });
    }
  }, []);

  useEffect(() => {
    startTextAnimation(0);
  }, []);

  window.onresize = () => setDim(canvasRef.current!);

  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <canvas className={styles.background} ref={canvasRef} />
        <img className={styles.cover} src={coverImage} alt="" />
        <div className={styles.panel}>
          <video
            className={styles.video}
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={video} type="video/mp4" />
          </video>
          <div className={styles.titleList}>
            <span className={styles.titleItem}>a</span>
            <span className={styles.titleItem}>m</span>
            <span className={styles.titleItem}>b</span>
            <span className={styles.titleItem}>V</span>
            <span className={styles.titleItem}>M</span>
          </div>
          <span className={styles.description}>
            A fast, secure, decentralized computing platform that runs on
            Bitcoin
          </span>
        </div>
        <div className={styles.card}>
          <div className={styles.window}>
            <div className={styles.bar}>
              <div className={styles.new}>
                <img className={styles.newImage} src={newImage} alt="" />
              </div>
              <div className={styles.barContainer}>
                <div className={styles.search}>
                  <img
                    className={styles.searchImage}
                    src={searchImage}
                    alt=""
                  />
                </div>
                <div className={styles.menu}>
                  <img className={styles.menuImage} src={menuImage} alt="" />
                </div>
                <img className={styles.minImage} src={minImage} alt="" />
                <img className={styles.maxImage} src={maxImage} alt="" />
                <img className={styles.closeImage} src={closeImage} alt="" />
              </div>
              <span className={styles.title}>founder@ambVM:~</span>
            </div>
            <div className={styles.content}>
              <div className={styles.information}>
                <span className={styles.info}>founder@ambVM</span>
                <span className={styles.infos}>:</span>
                <span className={styles.infoSpecial}>~</span>
                <span className={styles.infos}>$</span>
              </div>
              <span className={styles.title}>
                Always starting a blockchain for no reason, simply crazy.
              </span>
              <div className={styles.code} ref={codeRef} />
              {/* <div
                className={styles.action}
                onClick={() => {
                  window.open("https://docs.ambrosialabs.io/");
                }}
              >
                <span className={styles.actionText}>Read Docs</span>
                <svg className={styles.actionIcon}>
                  <use xlinkHref="#icon-r_h" />
                </svg>
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div
            className={styles.sectionContent}
            onClick={() => {
              window.open("https://ambrosialabs.io/");
            }}
          >
            <span className={styles.sectionText}>Made by</span>
            <img className={styles.sectionImage} src={ambrosiaImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
