import { FC } from "react";
import styles from "./index.module.scss";
import logoImage from "../../assets/images/logo.svg";

const Header: FC = () => {
  const handleLink = (_link: string) => {
    if (_link) {
      window.open(_link);
    }
  };

  return (
    <div className={styles.body}>
      <div className={styles.logo}>
        <img className={styles.logoImage} src={logoImage} alt="" />
        <span className={styles.title}>ambVM</span>
      </div>
      <div className={styles.list}>
        <svg
          className={styles.item}
          onClick={() => handleLink("https://twitter.com/ambrosia_fi")}
        >
          <use xlinkHref="#icon-x" />
        </svg>
        <svg
          className={styles.item}
          onClick={() => handleLink("https://discord.com/invite/P42kFQH8Ad")}
        >
          <use xlinkHref="#icon-discord" />
        </svg>
        <svg
          className={styles.item}
          onClick={() => handleLink("https://t.me/BTCambVM")}
        >
          <use xlinkHref="#icon-telegram" />
        </svg>
        <svg
          className={styles.item}
          onClick={() => handleLink("https://medium.com/@web3ambVM")}
        >
          <use xlinkHref="#icon-medium" />
        </svg>
        {/* <svg
          className={styles.item}
          onClick={() => handleLink("https://docs.ambrosialabs.io/")}
        >
          <use xlinkHref="#icon-Gitbook" />
        </svg> */}
      </div>
    </div>
  );
};

export default Header;
