import { FC } from "react";
import styles from "./index.module.scss";

const Footer: FC = () => {
  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <span className={styles.copyright}>
          {`© ${new Date().getFullYear()} ambVM.com`}
        </span>
        <div
          className={styles.link}
          onClick={() => {
            window.open("https://ambrosialabs.io/");
          }}
        >
          <span className={styles.linkText}>Made by Ambrosia LABS</span>
          <svg className={styles.linkIcon}>
            <use xlinkHref="#icon-external_link" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Footer;
